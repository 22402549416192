<template>
	<transition>
		<div ref="contentsWrap" class="contents-wrap" id="contentsWrap">
			<!-- <div class="contents-title-box">
                <div class="contents-title">부서 정보</div>
            </div> -->
			<div class="contents-box">
				<table class="table_form line-bin">
					<caption>
						<strong>선택부서명 일괄변경</strong>
					</caption>
					<colgroup>
						<col style="width:130px;" />
						<col style="width:auto;" />
					</colgroup>

					<thead class="sub_title_txt">
						<tr>
							<td colspan="2"><h2>선택 대상</h2></td>
						</tr>
					</thead>

					<tbody>
						<tr>
							<!-- <th scope="row"><label for="label01">검색 대상 노출 권한 <span class="icon_require">필수항목</span></label></th> -->
							<td>
								<DxDataGrid
									:data-source="contentData.selectedRowsData"
									:show-borders="true"
									:show-column-headers="true"
									:show-column-lines="false"
									:show-row-lines="true"
									:row-alternation-enabled="false"
									:allow-column-reordering="true"
									:no-data-text="noDataText()"
									:height="230"
									key-expr="id"
								>
									<!-- @cell-click="initSelectedData" -->

									<DxColumn caption="id" data-field="id" :width="80" alignment="center" :visible="false" />
									<DxColumn
										caption="상위 부서 정보"
										data-field="nameTree"
										:calculate-cell-value="nameTree"
										alignment="center"
										:visible="true"
									/>
									<DxColumn caption="선택 부서" data-field="deptNm" alignment="center" :width="200" :visible="true" />

									<!-- <DxSelection mode="single" /> -->
									<DxScrolling mode="virtual" />
								</DxDataGrid>
							</td>
						</tr>
					</tbody>
				</table>

				<div class="conts-row">
					<div class="conts-box">
						<div class="conts-title">바꿀 부서명*</div>
						<DxTextBox
							v-model="formData.deptNm"
							:styling-mode="config.stylingMode"
							:max-length="limitNumberTexts.maxLengths.deptNm"
							:width="350"
						>
							<DxValidator>
								<DxRequiredRule message="부서명은 필수입니다." />
							</DxValidator>
						</DxTextBox>
						{{
							limitNumberTexts.textLengths.deptNm
								? limitNumberTexts.textLengths.deptNm
								: formData.deptNm
								? formData.deptNm.length
								: 0
						}}/{{ limitNumberTexts.maxLengths.deptNm }}자
					</div>
				</div>
			</div>

			<div class="container-bottom-box">
				선택된 <span class="length-txt">{{ contentData.selectedRowsData.length }}</span
				>개 부서의 명칭을 모두 변경하시겠습니까?
			</div>
		</div>
	</transition>
</template>

<script>
import {
	DxDataGrid,
	DxSelection,
	DxScrolling,
	DxColumn,
	DxFilterRow,
	DxOperationDescriptions,
	DxFilterPanel,
	DxHeaderFilter,
} from 'devextreme-vue/data-grid';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import DxSwitch from 'devextreme-vue/switch';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { isSuccess } from "@/plugins/common-lib";

export default {
	components: {
		DxDataGrid,
		DxSelection,
		DxScrolling,
		DxColumn,
		DxFilterRow,
		DxOperationDescriptions,
		DxFilterPanel,
		DxHeaderFilter,
		DxTextBox,
		DxValidator,
		DxRequiredRule,
		DxSwitch,
		DxSelectBox,
	},
	props: {
		contentData: Object,
		deptList: Array,
	},
	watch: {},
	data() {
		return {
			config: {
				stylingMode: 'outlined', //[outlined, filled, underlined]
			},
			formData: {
				selectedDeptList: null, //선택 대상
				//tenantId: 21, //국세청
				parentId: null,
				deptNm: null,
				viewFl: 'Y',
			},
			limitNumberTexts: {
				textLengths: {},
				maxLengths: {
					deptNm: 35,
				},
			},
		};
	},
	computed: {
		/** @description: props 리스트에서 선택한 데이터 */
		getApiActionNm() {
			return this.contentData.apiActionNm;
		},
	},
	methods: {
		/** @description: 데이터가 없을 경우 출력 */
		noDataText() {
			return `해당 데이터가 없습니다.`;
		},
		/** @description: 상위 부서 정보 출력 */
		nameTree(rowData) {
			let isCheckLineBar = rowData.nameTree.includes('|');
			let topDeptTree = '-';
			if (isCheckLineBar) {
				const lastIndex = rowData.nameTree.lastIndexOf('|');
				topDeptTree = rowData.nameTree.substring(0, lastIndex).replaceAll('|', ' > ');
			}
			return topDeptTree;
		},
		/** @description : 라이프사이클 creaed시 호출되는 메서드 */
		createdData() {
			this.formData = { ...this.formData, ...this.contentData };
		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountedData() {
			this.$_eventbus.$on('ModalUpdateSelectedDept:onSaveData', async (e, resolve) => {
				if (!e.validationGroup.validate().isValid) {
					return false;
				}

				let formDatas = this.contentData.selectedRowsData.map(d => {
					d.deptNm = this.formData.deptNm;
					return { ...d };
				});
				console.log('formDatas >>> ', formDatas);

				//등록시 순서 값 설정
				/* if( this.formData.id === null ){
                        this.formData.deptOrd = deptOrd;
                    } */

				let data = {
					data: formDatas,
				};

				if (await this.$_Confirm('부서명을 일괄 변경하시겠습니까?')) {
					let payload = {
						actionname: 'DEPT_LIST_UPDATE',
						data: data,
						loading: true,
					};
					let res = await this.CALL_API(payload);
                    if( isSuccess(res) ) {
                        this.$_Toast('부서명이 일괄 변경되었습니다.');
                        resolve(200);
                    }
				}
			});
		},
		/** @description : 라이프사이클 destroyed시 호출되는 메서드 */
		destroyedData() {
			this.$_eventbus.$off('ModalUpdateSelectedDept:onSaveData');
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
	destroyed() {
		this.destroyedData();
	},
};
</script>

<style scoped>
.contents-title-box {
	height: 40px;
	position: relative;
	border-bottom: 1px solid #ccc;
	line-height: 40px;
}
.contents-title-box .contents-title {
	height: 40px;
	padding-left: 5px;
	display: inline-block;
	font-size: 0.9em;
}

.contents-box {
	width: 100%;
	background-color: #fff;
}
.contents-box .table_form td {
	padding: 10px 0;
}

.conts-row:not(:last-child) {
	margin-bottom: 20px;
}
.conts-row .conts-box {
	display: inline-block;
}
.conts-row .conts-box > div {
	display: inline-block;
}
.conts-row .conts-box .conts-title {
	width: 120px;
	display: inline-block;
}
.conts-row .conts-box .conts-input {
	display: -webkit-inline-box;
}

.container-bottom-box {
	margin-top: 20px;
	font-size: 0.9em;
	text-align: center;
}
.container-bottom-box .length-txt {
	color: tomato;
}
</style>
<style>
#modal_menu_config_menuicon .dx-texteditor-input {
	font-size: 15px;
	padding-top: 0px;
	padding-left: 2px;
}
</style>
